import {useEffect} from "react";

export default function Admin(){
    useEffect(() => {
        window.location.href = 'https://www.youtube.com/watch?v=IbgbMcFvyOY';
    }, []);

    return (
        <div>
        </div>
    );

}